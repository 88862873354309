import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

//import api
import { getTicketList } from '../../api/support';

//import component
import ChatList from "./ChatList";

const TicketList = () => {

  // redux-state
  const accountData = useSelector((state) => state.account);

  // state
  const [ticketList, setTicketList] = useState([])
  const [sender, setSender] = useState({})
  const [receiver, setReceiver] = useState({})
  const [filter, setFilter] = useState({})


  //function
  const handleSearch = (e) => {
    let { name, value } = e.target;
    setFilter(value)
  }

  const fetchTicketList = async (reqData) => {
    try {
      let filter = {
        search: reqData
      }
      const { status, result } = await getTicketList(filter);
      if (status == 'success') {
        setTicketList(result.ticketList)
        setSender(result.sender)
        setReceiver(result.receiver)
      }
    } catch (err) {
      console.log(err, 'errror');
    }
  }


  useEffect(() => {
    fetchTicketList(filter)
  }, [filter])

  return (
    <>
      <div className="dash_wrapper_flex_right">
        <div className="inner_subtitle_wrap inner_subtitle_wrap_flex">
          <h3>Support Ticket Details</h3>
        </div>
        <div className="search_grp">
          <input
            type="text"
            placeholder="Search"
            className="form-control"
            onChange={handleSearch}
          />
          <img
            src={require("../../assets/images/search_icon.png")}
            alt="Search"
            className="img-fluid search_icon"
          />
        </div>

        {ticketList && ticketList.length > 0 && ticketList.map((item, key) => {
          return (
            <div
              className="accordion faq_accordian support_ticket_accordian"
              id="faq_accordian"
            >
              <div className="accordion-item">
                <h2 className="accordion-header" id="headingOne">
                  <button
                    className="accordion-button"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target={"#collapseOne" + key}
                    aria-expanded="true"
                    aria-controls="collapseOne"
                  >
                    <label className="ticket_title">
                      Sub: <span>{item.categoryName}</span>
                    </label>
                    <div>
                      <label>[ Ticket ID: #{item.tickerId} ]</label>
                      <label>
                        [ Status: <span className={item.status == 'open' ? "txt_green" : "txt_red"}>{item.status}</span> ]
                      </label>
                    </div>
                  </button>
                </h2>
                <ChatList
                  key={key}
                  eventKey={key}
                  ticketRecord={item}
                  sender={sender}
                  receiver={receiver}
                />
              </div>
            </div>
          )
        })}
        {
          ticketList && ticketList.length <= 0 &&
          <div className="text-center">
            <p>No Tickets found</p>
          </div>
        }
      </div>

    </>
  )
}

export default TicketList;