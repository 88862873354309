import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import CurrencyFormat from 'react-currency-format';
import { useSelector } from "react-redux";
// import api
import { getRedemption, getStakingCurrency } from "../../api/staking";

// import lib
import { dateTimeFormat } from "../../lib/dateTimeHelper";
import { toFixed, truncateDecimals } from "../../lib/roundOf";

// RedemptionHistory History Table Data


const RedemptionHistory = () => {
    // state
    const [data, setData] = useState([]);
    const [count, setCount] = useState(0);
    const [pageDoc, setPageDoc] = useState({
        page: 1, limit: 20, coin: 'all',
    });
    const [currencyData, setCurrencyData] = useState([])
    const { page, limit, coin } = pageDoc;
    const priceConversion = useSelector((state) => state.priceConversion);

    //function
    const handleChange = (e) => {
        e.preventDefault();
        const { name, value } = e.target;
        let filterData = {
            ...pageDoc,
            [name]: value, 'page': 1,
            'limit': 20
        }

        setPageDoc(filterData)
        getData(filterData)
    }

    const getData = async (reqData) => {
        try {
            const { status, result } = await getRedemption(reqData);
            if (status) {
                let newRecord = [];
                result.data && result.data.length > 0 && result.data.map((item, key) => {
                    newRecord.push({
                        "date": item.createdAt,
                        "coin": item.coin,
                        "amount": truncateDecimals(item?.stakeAmount, item.decimals) + " " + item.coin,
                        "APY": item.APY + " %",
                        "interestEarned": truncateDecimals(item?.amount, item.decimals) + " " + item.coin,
                        "type": item.StakeType,
                    })
                })
                setData(newRecord);
                setCount(result?.count);
            }
        } catch (err) {
            console.log(err, 'errro')
        }
    };

    const fetchCurrency = async () => {
        try {
            const { status, result } = await getStakingCurrency();
            if (status) {
                setCurrencyData(result)
            }
        } catch (err) {
            console.log(err, 'error')
        }
    }




    useEffect(() => {
        let reqData = {
            page, limit, coin
        }
        getData(reqData)
        fetchCurrency()
    }, [page, limit, coin])



    const columns = [
        {
            name: "Date",
            selector: (row) => row.date,
            cell: (record) => `${dateTimeFormat(record.date)}`,
        },
        {
            name: "Coin",
            selector: (row) => row.coin,
            cell: (record) => `${record.coin}`,
        },
        {
            name: "Amount",
            cell: (record) => {
                return <CurrencyFormat displayType={'text'} thousandSeparator={true} value={record?.amount} />
            },
        },
        {
            name: "APY",
            cell: (record) => `${record.APY}`,
        },
        {
            name: "Interest Earned",
            cell: (record) => {
                return (
                    <CurrencyFormat displayType={'text'} thousandSeparator={true} value={(record.interestEarned)} />
                )
            }
        },
        {
            name: "Interest in ($)",
            cell: (record) => {
                let PriceCnv = priceConversion.find(
                    (el) =>
                        el.baseSymbol == record.coin && el.convertSymbol == "USD"
                );
                let marketPrice = 1
                if (PriceCnv) marketPrice = PriceCnv.convertPrice
                return (
                    <CurrencyFormat displayType={'text'} thousandSeparator={true} value={toFixed(parseFloat(record.interestEarned) * marketPrice, 2)} />
                )
            }
        },
        {
            name: "Type",
            selector: (row) => row.type,
            cell: (record) => `${record.type}`,
        },
    ];

    return (
        <>
            <div className="inner_title_wrapper">
                <h2 className="inner_title">Redemption History</h2>
            </div>
            <div className="dash_box">
                <div class="input-group">
                    <select value={coin}
                        name="coin"
                        onChange={handleChange} className="form-select primary_asset_select">
                        <option value={'all'}>All</option>
                        {
                            currencyData && currencyData.length > 0 && currencyData.map((item, key) => {
                                return (
                                    <option value={item.coin} key={key}>
                                        {item.coin}
                                    </option>
                                )
                            })
                        }
                    </select>
                </div>
                <div className="primary_datatable">
                    <DataTable
                        columns={columns}
                        data={data}
                        pagination
                        paginationServer
                        paginationTotalRows={count}
                        paginationPerPage={limit}
                        paginationRowsPerPageOptions={[20, 30, 40, 50]}
                        onChangePage={(page) =>
                            setPageDoc({ ...pageDoc, ...{ page: page } })
                        }
                        onChangeRowsPerPage={(limit) =>
                            setPageDoc({ ...pageDoc, ...{ page: 1, limit: limit } })
                        }
                    />
                </div>
            </div>
        </>
    );
};

export default RedemptionHistory;
