import React, { useEffect, useState } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';

// import lib
import isLogin from '../../lib/isLogin';

const ConditionRoute = ({ type, children, ...rest }) => {
   

   const currentLocation = useLocation();

   const siteDoc = useSelector((state) => state.siteSetting)
   
   const [restrictData, setresData] = useState(true);

   // useEffect(() => {
   //    if (siteDoc) {
   //       if (currentLocation.pathname == "/trade" && !siteDoc.tradeAccess) {
   //          setresData(false);
   //       } else if (currentLocation.pathname == "/staking" && !siteDoc.stakeAccess) {
   //          setresData(false);
   //       } else if (currentLocation.pathname == "/wallet" && !siteDoc.walletAccess) {
   //          setresData(false);
   //       } else if (currentLocation.pathname == "/transaction-history" && !siteDoc.transactionAccess) {
   //          setresData(false);
   //       } else if (currentLocation.pathname == "/stakinghistory" && !siteDoc.historyAccess) {
   //          setresData(false);
   //       } else if (currentLocation.pathname == "/live-events" && !siteDoc.liveEventAccess) {
   //          setresData(false);
   //       }
   //    }


   // }, [])

     if(type == 'auth' && isLogin() === true) {
        return <Navigate to='/' />
     } else if(type == 'private' && isLogin() !== true) {
        return <Navigate to='/login' />
     }else if (restrictData == false) {
      return <Navigate to='/' />
   }

     return children;
};

export default ConditionRoute;