// import config
import axios, { handleResp } from '../config/axios'







export const getStaking = async (params) => {
    try {
        let respData = await axios({
            'method': 'get',
            'url': `/api/user/getStaking`,
            params
        });

        return {
            status: "success",
            loading: false,
            result: respData.data.result
        }
    }
    catch (err) {
        handleResp(err, 'error')
        return {
            status: "failed",
            loading: false,
        }
    }
}

export const getStackingEstimate = async (params) => {
    try {
        let respData = await axios({
            'method': 'get',
            'url': `/api/user/getStackingEstimate`,
            params
        });

        return {
            status: "success",
            loading: false,
            result: respData.data.result
        }
    }
    catch (err) {
        handleResp(err, 'error')
        return {
            status: "failed",
            loading: false,
        }
    }
}


export const getStakingCurrency = async (params) => {
    try {
        let respData = await axios({
            'method': 'get',
            'url': `/api/user/getStakingCurrency`,
            params
        });

        return {
            status: "success",
            loading: false,
            result: respData.data.result
        }
    }
    catch (err) {
        handleResp(err, 'error')
        return {
            status: "failed",
            loading: false,
        }
    }
}




export async function stakeOrderPlace(data) {
    try {
        let respData = await axios({
            url: "api/user/stake/orderPlace",
            method: "post",
            data,
        });
        return {
            status: true,
            loading: false,
            result: respData.data.result,
            message: respData.data.message,
        };
    } catch (err) {
        return {
            status: false,
            message: err.response.data.message,
        };
    }
}



export async function stakeLockedOrderPlace(data) {
    try {
        let respData = await axios({
            url: "api/user/orderPlaceLocked",
            method: "post",
            data,
        });
        return {
            status: true,
            loading: false,
            result: respData.data.result,
            message: respData.data.message,
        };
    } catch (err) {
        return {
            status: false,
            message: err.response.data.message,
        };
    }
}



export const getOrderList = async (params) => {
    try {
        let respData = await axios({
            'method': 'get',
            'url': `/api/user/stake/orderList`,
            params,
        });

        return {
            status: 'success',
            loading: false,
            result: respData.data.result
        }
    }
    catch (err) {
        handleResp(err, 'error')
        return {
            status: 'failed',
            loading: false,
            message: err.response.data.message
        }
    }
}


export async function StakeCancelOrder(pairId) {
    try {
        let respData = await axios({
            url: `api/user/stake/cancel/${pairId}`,
            method: "delete",
        });
        return {
            status: true,
            loading: false,
            result: respData.data.result,
            message: respData.data.message,
        };
    } catch (err) {
        return {
            status: false,
            loading: false,
            message: err.response.data.message,
        };
    }
}


export async function getStakeHistory(reqData) {
    try {
        let respData = await axios({
            url: "api/user/stake/settleHistory",
            method: "get",
            params: reqData
        });
        return {
            status: true,
            result: respData.data.result,
            message: respData.data.message,
        };
    } catch (err) {
        return {
            status: false,
            message: err.response.data.message,
        };
    }
}


export async function getRedemption(reqData) {
    try {
        let respData = await axios({
            url: "api/user/redemptionHistory",
            method: "get",
            params: reqData
        });
        return {
            status: true,
            result: respData.data.result,
            message: respData.data.message,
        };
    } catch (err) {
        return {
            status: false,
            message: err.response.data.message,
        };
    }
}