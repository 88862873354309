import React, { createContext, useContext, useState, useEffect } from "react";
import { setTheme, getTheme } from "../lib/localStorage"
const ThemeContext = createContext();

export const ThemeProvider = ({ children }) => {
  const [theme, setThemes] = useState("dark");

  const toggleTheme = () => {
    setTheme(theme === "light" ? "dark" : "light");
    setThemes((prevTheme) => (prevTheme === "light" ? "dark" : "light"));
  };
  useEffect(() => {
    setThemes(getTheme())
  }, [])
  return (
    <ThemeContext.Provider value={{ theme, toggleTheme }}>
      {children}
    </ThemeContext.Provider>
  );
};

export const useTheme = () => {
  return useContext(ThemeContext);
};
